import React, { useState, useEffect } from 'react';
import { Col, Card, CardImg, CardText, CardSubtitle, CardBody } from 'reactstrap';
import moment from 'moment';
import imgPlaceholderModel from '../assets/placeholderModel.png';
import imgPlaceholderPhoto from '../assets/placeholderPhoto.png';

export default function Product(props) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);

    const momentBusinessDays = require('moment-business-days');
    momentBusinessDays.updateLocale('us', {
        holidays: [
            '11-26-2020',
            '11-27-2020',
            '12-24-2020',
            '12-25-2020',
            '01-01-2021',
            '05-31-2021',
            '07-05-2021',
            '09-06-2021',
            '11-25-2021',
            '11-26-2021',
            '12-23-2021',
            '12-24-2021',
        ],
        holidayFormat: 'MM-DD-YYYY',
    });

    useEffect(() => {
        if (imageLoaded && props.isPhotoImage && props.product.imagePhotoFilename)
            setImgSrc(`/products/${props.product.imagePhotoFilename}`);
        else if (
            imageLoaded &&
            !props.isPhotoImage &&
            props.product.imageModelFilename
        )
            setImgSrc(`/products/${props.product.imageModelFilename}`);
        else if (props.isPhotoImage) setImgSrc(imgPlaceholderPhoto);
        else if (!props.isPhotoImage) setImgSrc(imgPlaceholderModel);
    }, [imageLoaded, props]);

    return (
        <Col xs="12" sm="6" md="4" lg="4" xl="3">
            <Card className="mb-4 box-shadow">
                <CardImg
                    top
                    style={{ display: 'block' }}
                    src={imgSrc}
                    alt={props.product.title}
                    onLoad={() => setImageLoaded(true)}
                />
                <CardBody>
                    <h5>{props.product.title}</h5>
                    <CardSubtitle>
                        <small className="text-muted">
                            {props.product.subtitle}
                        </small>
                    </CardSubtitle>
                    <CardText />
                    <CardText>
                        Lead time is{' '}
                        <span className="font-weight-bolder">
                            {momentBusinessDays(props.product.shipDate)
                                .businessDiff(moment(), 'days')
                                .toString()
                                .concat(' business days')}
                        </span>
                        <br />
                        Now shipping on{' '}
                        <span className="font-weight-bolder">
                            {props.product.shipDate}
                        </span>
                    </CardText>
                    <div className="d-flex align-items-center">
                        <small className="text-muted">
                            {'Updated '.concat(
                                moment(props.product.lastUpdate).fromNow()
                            )}
                        </small>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}
